import React from "react";
import Helmet from "react-helmet";
import { Blog_meta_contents } from "../constants/SEO_title_content";

const getSchemaOrgJSONLD = ({
  isDocsPost,
  isBlogPost,
  url,
  title,
  image,
  description,
  datePublished,
  dateModified,
  keywords,
  authorName,
  sitename,
  pathname,
}) => {
  const schemaOrgJSONLD = {
    "@context": "http://schema.org",
    "@type": "BlogPosting",
    url: url + pathname,
    name: title,
    alternateName: title,
  };
  return isBlogPost
    ? {
        ...schemaOrgJSONLD,
        mainEntityOfPage: {
          "@type": "Website",
          "@id": sitename,
        },
        headline: title,
        image: {
          "@type": "ImageObject",
          url: image,
        },
        datePublished,
        dateModified,
        keywords,
        author: {
          "@type": "person",
          name: authorName,
        },
        publisher: {
          "@type": "Organization",
          url: url,
          name: sitename,
          logo: {
            "@type": "ImageObject",
            url: "https://optinly.com/logo.png",
          },
        },
        description,
      }
    : isDocsPost
    ? {
        "@context": "https://schema.org/",
        "@type": "Article",
        mainEntityOfPage: {
          "@type": "WebPage",
          "@id": "https://www.optinly.com",
        },
        headline: title,
        image: {
          "@type": "ImageObject",
          url: image,
        },
        author: {
          "@type": "Person",
          name: authorName,
        },
        publisher: {
          "@type": "Organization",
          url: url,
          name: sitename,
          logo: {
            "@type": "ImageObject",
            url: "https://optinly.com/logo.png",
          },
        },
        datePublished,
        dateModified,
      }
    : {
        ...schemaOrgJSONLD,
        mainEntityOfPage: {
          "@type": "Website",
          "@id": sitename,
        },
        image: {
          "@type": "ImageObject",
          url: "https://optinly.com/logo.png",
        },
        headline: title,
        datePublished: "2020-06-21",
        author: {
          "@type": "person",
          name: "Optinly",
        },
        publisher: {
          "@type": "Organization",
          url: url,
          name: sitename,
          logo: {
            "@type": "ImageObject",
            url: "https://optinly.com/logo.png",
          },
        },
      };
};

const Metatags = (props) => {
  const uri = props.uri;

  const title =
    (uri &&
      Blog_meta_contents.filter((l) => {
        return l.uri === props.uri;
      }).map((l) => {
        return l.title;
      })[0]) ||
    props.title;

  const description =
    (uri &&
      Blog_meta_contents.filter((l) => {
        return l.uri === props.uri;
      }).map((l) => {
        return l.description;
      })[0]) ||
    props.description;

  const image = props.thumbnail || props.image || null;
  const pathname = props.pathname || "/";
  const url = props.url || "https://www.optinly.com";
  const datePublished = props.datePublished;
  const dateModified = props.datemodified;
  const isBlogPost = props.isBlogPost;
  const authorName = props.author;
  const sitename = props.sitename;
  const keywords = props.keywords;
  const isDocsPost = props.isDocsPost;

  const schemaOrgJSONLD = getSchemaOrgJSONLD({
    url,
    title,
    image,
    description,
    datePublished,
    dateModified,
    isBlogPost,
    authorName,
    sitename,
    keywords,
    pathname,
    isDocsPost,
  });

  return (
    <Helmet title={title}>
      {/* General tags */}
      <meta name="robots" content="index, follow" />
      <meta name="yandex-verification" content="6da7a454145b81a6" />
      <meta name="title" content={title} />
      <meta name="description" content={description} />
      {keywords ? <meta name="keywords" content={keywords} /> : null}
      <meta name="image" content={image} />
      {/*<link rel="canonical" key={url+props.canonical} href={url+props.canonical} data-react-helmet="true" />*/}

      {/* Schema.org tags */}
      <script type="application/ld+json">
        {JSON.stringify(schemaOrgJSONLD)}
      </script>

      {/* OpenGraph tags */}
      <meta property="og:url" content={url + pathname} />
      {isBlogPost ? <meta property="og:type" content="article" /> : <meta property="og:type" content="website" />}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />

      {/* Twitter Card tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content="@optinly." />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />
      <meta name="twitter:domain" content="optinly.com" />
    </Helmet>
  );
};

export default Metatags;
